<template>
  <div class="main__pages">
    <div v-if="!mounted" class="full__screen">
      <app-loader></app-loader>
    </div>
    <div v-else class="main__content">
      <div class="main__orders">
        <add-order
          :status="userData.status"
          @addNewOrderDrop="addNewOrder"
          @getSecondModal="getSecondModal"
        ></add-order>
      </div>
      <div class="main__pages--fixed">
        <div class="main__filters">
          <filter-block
            :isFiltered="true"
            :filterDateRange="filterDateRange"
            @changeFilterSearch="changeFilterSearch"
            @changeFilterDate="changeFilterDate"
            :filterTypeOrder="getUniqTypeOrder"
            @filterTypeOrder="filterTypeOrder"
            :filterStatusOrder="getUniqStatusOrder"
            @filterStatusOrder="filterStatusOrder"
            :filterTonnage="getUniqTonnage"
            @filterTonnage="filterTonnage"
            :filterTypeCargo="getUniqTypeCargo"
            @filterTypeCargo="filterTypeCargo"
            :filterPayment="getUniqPayment"
            @filterPayment="filterPayment"
          ></filter-block>
          <tag-block
            tabType="orders"
            :today="today"
            :tomorrow="tomorrow"
            :countAll="getCountAllOrders"
            :countToday="filteredListToday.length"
            :countTommorow="filteredListTomorrow.length"
            :clearTab="clearTab"
            @changeFilterDate="changeFilterDate"
            :count="filteredList.length"
            :countLimit="countLimit"
          ></tag-block>
        </div>
      </div>
      <orders-list
        :orders="loadMoreOrders"
        :updateOrder="updateOrder"
        @openModalOrderHandler="openModalOrderHandler"
        @currentOrderHandler="currentOrderHandler"
        @viewGPSHandler="viewGPSHandler"
        @copyOrder="copyOrder"
        :page="page"
        :totalPage="totalPage"
        @addMoreOrders="isAddMoreOrders"
        @loadingMoreOrders="loadingMoreOrders"
      ></orders-list>
      <order-modal
        v-if="orderModal"
        :currentOrder="currentOrder"
        :orderId="currentOrder.orderId"
        :viewGPS="viewGPS"
        :viewGPSLink="viewGPSLink"
        :viewGPSDriver="viewGPSDriver"
        :viewGPSDriverData="viewGPSDriverData"
        :viewGPSOrder="viewGPSOrder"
        :viewRoute="viewRoute"
        @closeModalOrderHandler="closeModalOrderHandler"
        @copyOrder="copyOrder"
        @getUpdateOrder="getUpdateOrder"
        @getCancelOrder="getCancelOrder"
        @getReopenOrder="getReopenOrder"
      ></order-modal>
      <new-order
        v-if="newOrder"
        :newOrderType="newOrderType"
        :currentOrderData="currentOrderData"
        @closeModalOrderHandler="closeModalOrderHandler"
        @getUpdateOrder="getUpdateOrder"
        @getNewOrder="getNewOrder"
      ></new-order>
      <modal-upload
        :openUploadSecondModal="openUploadSecondModal"
        @getSecondModal="getSecondModal"
      ></modal-upload>
    </div>
  </div>
</template>

<script>
// Preloader
import AppLoader from "../components/ui/app-loader";
// Components
import FilterBlock from "../components/order/filter-block";
import TagBlock from "../components/order/tag-block";
import OrdersList from "../components/order/orders-list";
import OrderModal from "../components/order/order-modal";
import NewOrder from "../components/order/new-order";
import AddOrder from "../components/main/add-order";
import ModalUpload from "../components/order/modal-upload";
// Data
import { orderStatus } from "../data/order-status";
import { orderPayment } from "../data/order-payment";
import { orderOptionsData } from "../data/order-options";
import { carBoxType } from "../data/car-box-type";
// Store
import { mapActions, mapState } from "vuex";

export default {
  name: "Orders",
  components: {
    FilterBlock,
    TagBlock,
    OrdersList,
    AppLoader,
    OrderModal,
    NewOrder,
    AddOrder,
    ModalUpload
  },
  data() {
    return {
      mounted: false,
      statusList: orderStatus,
      paymentList: orderPayment,
      optionDopList: orderOptionsData,
      filterDateRange: [],
      filterSearch: "",
      selectedTypeOrder: [],
      selectedStatusOrder: [],
      selectedTonnage: [],
      selectedTypeCargo: [],
      selectedPayment: [],
      zaprosOrders: 0,
      timeout: 20000,
      orderModal: false,
      viewRoute: [],
      newOrder: false,
      newOrderType: "",
      currentOrderData: {},
      currentOrder: {},
      viewGPS: false,
      viewGPSLink: "",
      viewGPSDriver: null,
      viewGPSDriverData: {},
      viewGPSOrder: null,
      serverPath: "https://d.tkglp.ru/uploads/",
      getCachePingInterval: 0,
      updateOrder: 0,
      pingOrders: [],
      clearTab: false,
      openUploadSecondModal: false,
      page: 1,
      limit: 200,
      reloading: false,
      addMoreOrders: false,
      ordersAll: []
    };
  },
  created() {
    this.setActivePage();
    // Получение заказов
    this.GET_ORDERS().then(() => {
      this.mounted = true;
    });
    if (!this.sessionId) {
      this.getSessionOnServer();
    }
    this.getCachePingInterval = setInterval(() => {
      this.getCachePing().then(() => {
        if (this.pingOrders.length) {
          this.pingOrders.forEach((item) => {
            this.UPDATE_ORDER(item);
          });
          // Очистка кеша
          this.clearCachePing();
        }
      });
    }, this.timeout);
  },
  beforeDestroy() {
    clearInterval(this.getCachePingInterval);
  },
  computed: {
    ...mapState(["userData", "token", "orders", "sessionId"]),
    // Сегодня в милисекундах отрезать текущее время
    today() {
      const date = new Date();
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      return new Date(date).getTime();
    },
    // Завтра в милисекундах
    tomorrow() {
      return this.today + 86400000;
    },
    // Массив заказов
    orderStatusTempList() {
      this.orders.map((item) => {
        // Статус заказа
        const i = +item.orderStatus - 1;
        item.orderStatusData = {
          color: `order__status--${this.statusList[i].color}`,
          name: this.statusList[i].name,
          title: this.statusList[i].title
        };
        // Оплата заказа
        let y = 1;
        if (item.paymentClientStatus) {
          y = 0;
        } else if (item.orderInvoice) {
          y = 2;
        }
        item.statusPayment = {
          color: `main__status--${this.paymentList[y].color}`,
          name: this.paymentList[y].name
        };
        // Форматирование даты для фильтрации
        item.requestNumber;
        item.formatedDate = new Date(item.createDate).getTime();
        item.formatedTime = new Date(item.createTime).getTime();
        item.formatedDateTime = item.formatedDate + item.formatedTime;
        // Дата и время для отображения
        item.dateToView = new Date(item.createDate).toLocaleDateString();
        item.timeToView = new Date(item.createTime).toLocaleTimeString("ru-RU", {
          timeZone: "Europe/Moscow",
          hour: "2-digit",
          minute: "2-digit"
        });
        // Дата для отображения в форме
        item.dateToForm = this.getTrueDataToInput(item.createDate, "-");
        // Тоннаж
        item.tonn = "";
        item.tonnFilter = null;
        if (item.tonnage) {
          let tonnageText = "тонн";
          if (item.tonnage == 1) {
            tonnageText = "тонны";
          }

          if (item.tonnage == 0.5) {
            item.tonnage = 500;
            tonnageText = "кг.";
          }
          if (item.tonnage == 0.8) {
            item.tonnage = 800;
            tonnageText = "кг.";
          }
          if (item.tonnage == 1.51) {
            tonnageText = "тонн удл.";
            item.tonnage = 1.5;
          }
          const tonnageView = `до ${item.tonnage} ${tonnageText}`;
          item.tonn = tonnageView;
          item.tonnFilter = tonnageView;
        } else if (!item.tonnage && item.orderType === 2) {
          item.tonn = "Грузчик";
        }
        // Тип кузова
        item.boxTypeName = "";
        carBoxType.map((elem) => {
          if (elem.value === item.boxType) {
            item.boxTypeName = elem.view;
          }
        });
        // Тип заказа
        if (item.orderType === 1) {
          item.orderTypeView = "Транспорт";
        } else {
          item.orderTypeView = "Грузчик";
        }
        // Вывод массива точек маршрута при наведении
        item.routeTitle = "";
        if (item.allPointsRoute) {
          item.routeTitle = item.allPointsRoute.map((item) => {
            return item.address;
          });
          item.routeTitle = item.routeTitle.join("\n");
        }
        // Массив дополнительных опций
        item.dopOptions = [];
        // for (let key in item.orderOption) {
        //   if (item.orderOption[key]) {
        //     item.dopOptions.push(this.optionDopList[key]);
        //   }
        // }
        for (let key in item.orderOption) {
          if (item.orderOption[key]) {
            item.dopOptions.push(key);
          }
        }
        // Ссылка на документ
        item.docFileLink = null;
        // if (item.docClientFile) {
        //   item.docFileLink = `${this.serverPath}order/${item.orderId}/doc/${item.docClientFile}`;
        // }
        // Переписываем данные по маршруту
        // const routeArray = [];
        // if (item.allPointsRoute.length > 0) {
        //   item.allPointsRoute.map((item) => {
        //     const route = {
        //       type: item.loaderType ? item.loaderType : "Выгрузка",
        //       arrivalTime: item.arrivalTime
        //         ? new Date(item.arrivalTime).toLocaleTimeString([], {
        //             hour: "2-digit",
        //             minute: "2-digit"
        //           })
        //         : "00:00",
        //       arrivalDate: item.arrival
        //         ? new Date(item.arrival).toLocaleDateString()
        //         : null,
        //       address: item.address ? item.address : null,
        //       departedTime: item.departedTime
        //         ? new Date(item.departedTime).toLocaleTimeString([], {
        //             hour: "2-digit",
        //             minute: "2-digit"
        //           })
        //         : "00:00",
        //       departedDate: item.departed
        //         ? new Date(item.departed).toLocaleDateString()
        //         : null,
        //       comment: item.shipper
        //     };
        //     routeArray.push(route);
        //   });
        // }
        // // if(item.firstPointRoute) {
        // //   routeArray.push(item.firstPointRoute);
        // // }
        // // if(item.lastPointRoute) {
        // //   routeArray.push(item.firstPointRoute);
        // // }
        // item.routeArray = [...item.allPointsRoute];
        // Данные по исполнителю
        item.executor = "Идет подбор исполнителя";
        item.gps = null;
        item.copy = null;
        item.carPhoto = [];
        item.driverDocPhoto = [];
        item.loaderDocPhoto = [];
        item.executorPhoto = "";
        if (item.driver && item.driver.length > 0) {
          const driver = item.driver[0];
          item.driverData = driver;
          // ФИО водителя
          item.executor = driver.driverFullName;
          // Ссылка GPS
          item.gps = driver.driverDevice ? "" : driver.driverGps;
          // item.gps = driver.driverGps;
          // Мобильное приложение
          item.driverDevice = driver.driverDevice;
          // Данные для копирования
          let driverCarData = "";
          if (driver.driverCarModel && driver.driverCarNumber) {
            driverCarData = `${driver.driverCarModel} ${driver.driverCarNumber}`;
          }
          let driverPhoneCopy = "";
          if (driver.driverPhone && item.orderStatus < 3) {
            driverPhoneCopy = `\nТел.: ${driver.driverPhone}`;
          }
          item.copy = `${driver.driverFullName}\nПаспорт ${driver.driverPassportSeries} ${driver.driverPassportNumber} выдан ${driver.driverPassportIssue}, ${driver.driverPassportDateIssue}\n${driverCarData}${driverPhoneCopy}`;
          // ДАННЫЕ ДЛЯ МОДАЛЬНОГО ОКНА
          // Фото водителя
          item.executorPhoto = driver.driverPhoto;
          // Фото автомобиля
          if (driver.driverCarPhoto) {
            for (let key in driver.driverCarPhoto) {
              if (driver.driverCarPhoto[key]) {
                item.carPhoto.push(
                  `${this.serverPath}cars/${driver.driverCarId}/${driver.driverCarPhoto[key]}`
                );
              }
            }
          }
          // Массив ФИО водителя
          item.driverFIO = [
            driver.driverLastName,
            driver.driverFirstName,
            driver.driverMiddleName
          ];
        } else if (item.loader && item.loader.length > 0) {
          const loader = item.loader[0];
          item.loaderData = loader;
          let pathDocs = "loader",
            pathId = loader.loaderId;
          if (loader.loaderDriver) {
            pathDocs = "drivers";
            pathId = loader.loaderDriver;
          }
          // ФИО грузчика
          item.executor = loader.loaderFullName;
          // Фото грузчика
          // item.executorPhoto = loader.loaderPhoto;
          item.executorPhoto = `${this.serverPath + pathDocs}/${pathId}/image/${
            loader.loaderPhoto
          }`;
          // Массив ФИО грузчика
          item.loaderFIO = [loader.loaderLastName, loader.loaderFirstName];
          let loaderPhoneCopy = "";
          if (loader.loaderPhone && item.orderStatus < 3) {
            loaderPhoneCopy = `\nТел.: ${loader.loaderPhone}`;
          }
          item.copy = `${loader.loaderFullName}\nПаспорт ${loader.loaderPassportSeries} ${loader.loaderPassportNumber} выдан ${loader.loaderPassportIssue}, ${loader.loaderPassportDateIssue}${loaderPhoneCopy}`;
          // Документы грузчика
          if (loader.loaderDocs && item.orderStatus < 3) {
            for (let key in loader.loaderDocs) {
              if (loader.loaderDocs[key].docFileName) {
                if (key > 0 && key < 3) {
                  item.loaderDocPhoto.push(
                    `${this.serverPath + pathDocs}/${pathId}/doc/${
                      loader.loaderDocs[key].docFileName
                    }`
                  );
                }
              }
            }
          }
        }
        if (
          item.executor === "Идет подбор исполнителя" &&
          item.orderStatus > 4 &&
          item.orderStatus != 12
        ) {
          item.executor = "";
        }
      });
      return this.orders;
    },
    // Убираем все архивные и удалённые заказы
    orderStatusList() {
      return this.orderStatusTempList.filter(
        (item) => +item.orderStatus !== 8 && +item.orderStatus !== 9
      );
    },
    // Вывод количества всех заказов
    getCountAllOrders() {
      let count = this.orderStatusList.length;
      if (this.orderStatusList.length > 1000) {
        count = "1000+";
      }
      return count;
    },
    // Уникальные значения фильтров
    getUniqTypeOrder() {
      const temp = [
        ...new Set(this.orderStatusList.map((item) => item.orderTypeView))
      ];
      return temp
        .filter((item) => {
          return item !== null;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        });
    },
    getUniqStatusOrder() {
      const temp = [
        ...new Set(
          this.orderStatusList.map((item) => item.orderStatusData.name)
        )
      ];
      return temp
        .filter((item) => {
          return item !== null;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        });
    },
    getUniqTonnage() {
      const temp = [
        ...new Set(this.orderStatusList.map((item) => item.tonnFilter))
      ];
      return temp
        .filter((item) => {
          return item !== null;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        });
    },
    getUniqTypeCargo() {
      const temp = [
        ...new Set(this.orderStatusList.map((item) => item.boxTypeName))
      ];
      return temp
        .filter((item) => {
          return item !== null;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        });
    },
    getUniqPayment() {
      const temp = [
        ...new Set(this.orderStatusList.map((item) => item.statusPayment.name))
      ];
      return temp
        .filter((item) => {
          return item !== null;
        })
        .sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
        });
    },
    // Список отсортированных заказов
    filteredList() {
      const filtered = this.orderStatusList
        .filter((item) => {
          return (
            this.filterDateRange.length === 0 ||
            (this.filterDateRange[0] === null &&
              this.filterDateRange[1] === null) ||
            (item.formatedDate >= this.filterDateRange[0] &&
              item.formatedDate <= this.filterDateRange[1])
          );
        })
        .filter(
          (item) =>
            this.selectedTypeOrder.length === 0 ||
            this.selectedTypeOrder.includes(item.orderTypeView)
        )
        .filter(
          (item) =>
            this.selectedStatusOrder.length === 0 ||
            this.selectedStatusOrder.includes(item.orderStatusData.name)
        )
        .filter(
          (item) =>
            this.selectedTonnage.length === 0 ||
            this.selectedTonnage.includes(item.tonnFilter)
        )
        .filter(
          (item) =>
            this.selectedTypeCargo.length === 0 ||
            this.selectedTypeCargo.includes(item.boxTypeName)
        )
        .filter(
          (item) =>
            this.selectedPayment.length === 0 ||
            this.selectedPayment.includes(item.statusPayment.name)
        )
        .filter((item) => {
          const order = item.orderId.toString();
          return (
            this.filterSearch === "" ||
            this.filterSearch == item.requestNumber ||
            this.filterSearch == item.orderId ||
            this.filterSearch == item.orderNumber ||
            order.includes(this.filterSearch) ||
            item.executor
              .toLowerCase()
              .indexOf(this.filterSearch.toLowerCase()) !== -1 ||
            item.routeTitle
              .toLowerCase()
              .indexOf(this.filterSearch.toLowerCase()) !== -1
          );
        });
      return filtered.sort((a, b) => {
        // return b.formatedDate - a.formatedDate;
        if (a.formatedDate > b.formatedDate) return -1;
        if (a.formatedDate < b.formatedDate) return 1;
        // при равных score сортируем по time
        if (a.formatedTime > b.formatedTime) return -1;
        if (a.formatedTime < b.formatedTime) return 1;
        return 0;
      });
    },
    // Заказы на завтра
    filteredListToday() {
      return this.orderStatusList.filter((item) => {
        return (
          item.formatedDate >= this.today && item.formatedDate <= this.today
        );
      });
    },
    // Заказы на сегодня
    filteredListTomorrow() {
      return this.orderStatusList.filter((item) => {
        return item.formatedDate == this.tomorrow;
      });
    },
    // ЛИМИТИРОВАНИЕ
    start() {
      return (this.page - 1) * this.limit;
    },
    totalPage() {
      return Math.ceil(this.filteredList.length / this.limit);
    },
    limitedOrders() {
      return this.filteredList.slice(this.start, this.limit * this.page);
    },
    loadMoreOrders() {
      return [...this.ordersAll, ...this.limitedOrders];
    },
    countLimit() {
      return this.loadMoreOrders.length;
    }
  },
  methods: {
    // Store
    ...mapActions([
      "GET_ORDERS",
      "CHANGE_ACTIVE_PAGE",
      "UPDATE_ORDER",
      "GET_SESSION",
      "REFRESH_TOKEN_HANDLER",
      "ALERT_MESSAGE",
      "ALERT_MESSAGE_TEXT"
    ]),
    // ЛИМИТИРОВАНИЕ
    loadingMoreOrders() {
      this.reloading = true;
      setTimeout(() => {
        this.ordersAll = [...this.loadMoreOrders];
        this.page = this.page + 1;
      }, 500);
      this.addMoreOrders = false;
    },
    isAddMoreOrders(value) {
      this.addMoreOrders = value;
    },
    // Текущая активная страница
    setActivePage() {
      this.CHANGE_ACTIVE_PAGE("orders");
    },
    // Изменить значение полей в фильтре
    changeFilterSearch(value) {
      this.filterSearch = value;
      this.clearTab = true;
    },
    changeFilterDate(value) {
      this.filterDateRange = value;
      this.clearTab = true;
    },
    filterTypeOrder(value) {
      this.selectedTypeOrder = value;
    },
    filterStatusOrder(value) {
      this.selectedStatusOrder = value;
    },
    filterTonnage(value) {
      this.selectedTonnage = value;
    },
    filterTypeCargo(value) {
      this.selectedTypeCargo = value;
    },
    filterPayment(value) {
      this.selectedPayment = value;
    },
    // Проверка строки на совпадение
    checkStringToSearch(search, string) {
      if (string.indexOf(search) === -1) {
        return true;
      } else {
        return false;
      }
    },
    // Модальное окно
    openModalOrderHandler(value) {
      this.viewGPS = false;
      this.orderModal = value;
    },
    // Создание нового заказа
    addNewOrder(value) {
      this.newOrder = true;
      this.newOrderType = value;
    },
    // Данные заказа в модальном окне
    currentOrderHandler(value) {
      this.currentOrder = value;
    },
    // Закрытие модального окна, очистка данных
    closeModalOrderHandler(value) {
      this.orderModal = value;
      this.newOrder = value;
      this.newOrderType = "";
      this.viewGPS = false;
      this.viewGPSLink = "";
      this.currentOrder = {};
      this.currentOrderData = {};
    },
    // Отмечаем обновлённый заказ
    getUpdateOrder(value) {
      this.updateOrder = value;
      setTimeout(() => {
        this.updateOrder = 0;
      }, 3000);
    },
    // Отмечаем создание заказа
    getNewOrder(value) {
      setTimeout(() => {
        this.ALERT_MESSAGE_TEXT({
          class: "success",
          text: `Новый заказ G${value} успешно создан`
        });
        this.ALERT_MESSAGE(true);
      }, 500);
    },
    // Отмечаем отмену заказа
    getCancelOrder(value) {
      setTimeout(() => {
        this.ALERT_MESSAGE_TEXT({
          class: "danger",
          text: `Заказ G${value} отменён`
        });
        this.ALERT_MESSAGE(true);
      }, 500);
    },
    // Отмечаем отмену заказа
    getReopenOrder(value) {
      setTimeout(() => {
        this.ALERT_MESSAGE_TEXT({
          class: "success",
          text: `Заказ G${value} успешно возвращён в работу`
        });
        this.ALERT_MESSAGE(true);
      }, 500);
    },
    // Показ GPS в модальном окне
    viewGPSHandler(data) {
      console.log(data);
      this.viewGPSLink = "";
      if (data.name === `gps` && data.value) {
        this.viewGPSLink = data.value;
      }
      if (data.name === `driver` && data.value) {
        this.viewGPS = true;
        this.viewGPSOrder = null;
        this.viewGPSDriver = data.value;
        this.viewGPSDriverData = data.driver;
      }
      if (data.name === `order` && data.value) {
        (this.viewRoute = data.route), (this.viewGPS = true);
        this.viewGPSDriver = null;
        this.viewGPSDriverData = {};
        this.viewGPSOrder = data.value;
        console.log(data.route);
      }
      this.orderModal = true;
    },
    // Перевод даты для отображения в поле формы
    getTrueDataToInput(data, delimiter) {
      let date = new Date(data),
        getDay = date.getDate(),
        getYear = date.getFullYear(),
        getMonth = date.getMonth() + 1;
      if (+getDay < 10) {
        getDay = "0" + getDay;
      }
      if (+getMonth < 10) {
        getMonth = "0" + getMonth;
      }
      const newDate = getYear + delimiter + getMonth + delimiter + getDay;
      return newDate;
    },
    // КОПИРОВАНИЕ ЗАКАЗА
    copyOrder(value) {
      this.orderModal = false;
      this.newOrder = true;
      this.currentOrderData = value;
      this.newOrderType = value.orderType;
    },
    // Асинхронная точечная проверка заказов
    async getCachePing() {
      await this.axios
        .post(
          `/api/client/cache/ping/${this.sessionId}`,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .then((response) => {
          this.pingOrders = response.data.response;
        })
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getCachePing();
              });
            } else {
              console.error("Ошибка в получении getCachePing" + e.message);
            }
          }
        });
    },
    // Асинхронная очистка кеша после получения
    async clearCachePing() {
      await this.axios
        .post(
          `/api/client/cache/clear/${this.sessionId}`,
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.clearCachePing();
              });
            } else {
              console.error("Ошибка в получении clearCachePing" + e.message);
            }
          }
        });
    },
    // Получение индетефикатора сессии
    getSessionOnServer() {
      this.axios
        .post(
          "/api/client/cache/session/get",
          {},
          {
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .then((response) => {
          this.GET_SESSION(response.data.response.cacheSessionId);
        })
        .catch((e) => {
          if (e.response) {
            const { code, message } = e.response.data;
            if (code === 401 && message === "Expired JWT Token") {
              this.REFRESH_TOKEN_HANDLER().then(() => {
                this.getSessionOnServer();
              });
            } else {
              console.error(
                "Ошибка в получении getSessionOnServer" + e.message
              );
            }
          }
        });
    },
    // Закрываем второе окно
    getSecondModal(value) {
      this.openUploadSecondModal = value;
    }
  },
  watch: {
    filteredList() {
      window.scrollTo(0, 0);
      this.ordersAll = [];
      this.page = 1;
    },
    //ЛИМИТИРОВАНИЕ
    loadMoreOrders() {
      setTimeout(() => {
        this.reloading = false;
      }, 2000);
    }
  }
};
</script>
